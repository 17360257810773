import React from 'react'
import styled from 'styled-components'
import {useRecoilState} from 'recoil'
import {alertAtom} from '../../store/recoil/alert'
import Button from '../button'
import {Style} from './Style'

/******************************

 - 사용법

 1.import
 import Alert from '../../module/alert'
 import {alertAtom} from '../../store/recoil/alert'

 // 리코일의 useSetRecoilState
 const Index = () => {
  const setAlert = useSetRecoilState(alertAtom)
  ...
 }

 2.일반적인 사용

 setAlert({
    title: '',  //title ? title : 타입을 따름 (success || error)
    type: 'success',
    message: '전송에 성공했습니다.',
    isShow: true
  }))

 3.confirm이고 action을 호출하는 경우

 setAlert({
    title: '',
    type: 'confirm',
    message: '로그아웃하시겠습니까?',
    isShow: true,
    action: {
      method: logoutAction,
      params: {
        token: '$user.token'
      }
    }
  }))

 4.confirm 이고 액션을 직접 입력하는 경우

 setAlert({
    title: '',
    type: 'confirm',
    message: '로그아웃하시겠습니까?',
    isShow: true,
    action: {
      method: (params?: any) => {
        alert(params.token)
      },
      params: {
        token: 'user.token'
      }
    }
  }))

 ******************************/

const Index = () => {

  const [alert, setAlert] = useRecoilState(alertAtom)

  const handle = (type: string) => {
    switch (type) {
      case 'confirm':
        const {method, params} = alert.action
        setAlert({
          isShow: false
        })
        return method(params)
      default:
        setAlert({
          isShow: false
        })
    }
  }

  const setTitle = (type: string) => {
    switch (type) {
      case 'success':
        return '성공'
      case 'error':
        return '오류'
      case 'confirm':
        return '확인'
      case 'choice':
        return '선택'
      default:
        return null
    }
  }

  return alert.isShow ? (
    <Style.Alert>
      <Style.Modal>
        <Style.Content>
          <Style.Header>
            <Style.Title>
              {alert.title ? alert.title : setTitle(alert.type!)}
            </Style.Title>
            <Style.Close onClick={() => handle('')}>
              <span>&times;</span>
            </Style.Close>
          </Style.Header>
          <Style.Body>
            <Style.Description>
              {
                alert.message ?
                  alert.message.toString().split('\n').map((line: any, index: number) => {
                    return (
                      <span key={index}>{line}<br/></span>
                    )
                  })
                  : ''
              }
            </Style.Description>
          </Style.Body>
          <Style.Footer>
            {
              alert.type === 'confirm' &&
                <Button
                    margin={'1rem .5rem 0 0'}
                    padding={'1rem 4.5rem'}
                    radius={'.5rem'}
                    bgcolor={'#3f3b3f'}
                    color={'#ffffff'}
                    themecolor={'white'}
                    disabled={false}
                    onClick={() => handle('')}
                >
                    취소
                </Button>
            }
            <Button
              margin={'1rem 0 0 .5rem'}
              padding={'1rem 4.5rem'}
              radius={'.5rem'}
              bgcolor={'#3f3b3f'}
              color={'#ffffff'}
              themecolor={'dark-gray'}
              disabled={false}
              onClick={() => handle(alert.type!)}
            >
              확인
            </Button>
          </Style.Footer>
        </Style.Content>
      </Style.Modal>
    </Style.Alert>
  ) : null
}

export default Index
