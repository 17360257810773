import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {authAtom} from '../recoil/auth'

export const AuthAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setAuth = useSetRecoilState(authAtom)

  const authLogin = (userId: string, password: string) => {

    loader({
      isLoading: false
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/v1/user/login',
      params: {userId: userId, password: password}
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAuth(response.data.payload)
        navigate('/')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: '오류',
          type: 'alert',
          message: '잘못된 아이디 또는 비밀번호 입니다.',
          isShow: true
        })
      }
    )

  }

  const authAdminLogout = (params: any) => {

    defaultService.handleService({
      method: 'post',
      endPoint: '/user/logout',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAuth('')
        navigate('/auth')
      },
      error => {
        loader({
          isLoading: false
        })

        setAuth('')
        navigate('/auth')

        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const authLogout = (params: any) => {

    defaultService.handleService({
      method: 'post',
      endPoint: '/user/adminlogout',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAuth('')
        navigate('/admin/auth')
      },
      error => {
        loader({
          isLoading: false
        })

        setAuth('')
        navigate('/auth')

        // setAlert({
        //   title: error.name,
        //   type: 'alert',
        //   message: error.message,
        //   isShow: true
        // })
      }
    )
  }

  return {
    authLogin,
    authAdminLogout,
    authLogout
  }
}
