import styled from "styled-components";
import {Routes, Route, useNavigate} from 'react-router-dom'
import {lazy, Suspense} from "react";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
`

const Index = () => {

  const List = lazy(() => import('./List'))
  const Regis = lazy(() => import('./Regis'))

  return<Container>
    <Suspense fallback={''}>
      <Routes>
        <Route path={'/list'} element={<List />} />
        <Route path={'/write'} element={<Regis />} />
        <Route path={'/:id/modify'} element={<Regis />} />
      </Routes>
    </Suspense>
  </Container>
}

export default Index
