import styled from "styled-components";
import {Routes, Route, useNavigate} from 'react-router-dom'
import {lazy, Suspense} from "react";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
`

const Index = () => {

  const List = lazy(() => import('./List'))
  const Regis = lazy(() => import('./Regis'))

  return<Container>
    <Suspense fallback={'/'}>
      <Routes>
        <Route path={'/pop/list'} element={<List />} />
        <Route path={'/pop/write'} element={<Regis />} />
        <Route path={'/pop/:id/modify'} element={<Regis />} />
      </Routes>
    </Suspense>
  </Container>
}

export default Index

/*
import {lazy, Suspense} from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
`

const Board = styled.div`
  display: flex;
  flex: 1;
`

const Index = () => {
  const Index = lazy(() => import('./Index'))
  const Detail = lazy(() => import('./Detail'))

  return (
    <Container>
      <Board>
        <Suspense fallback={''}>
          <Routes>
            {['/', '/:pageNum'].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={<Index/>}
              />
            ))}

            {['/detail/:id'].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={<Detail/>}
              />
            ))}
          </Routes>
        </Suspense>
      </Board>
    </Container>
  )
}

export default Index

 */
