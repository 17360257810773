import React, {useState} from 'react'
import {Link, NavLink, useLocation} from 'react-router-dom'
import styled, {css} from 'styled-components'
import Svg from '../../module/svg/'
import {authAtom} from '../../store/recoil/auth'
import {useRecoilValue} from 'recoil';

import {Style} from './style/Nav'

const contentMenu = [
  {
    id: 0,
    label: '메인',
    sub: [
      {
        id: 's0',
        label: '팝업관리',
        path: '/main/pop/list'
      },
    ]
  },

  {
    id: 1,
    label: 'IR',
    sub: [
      {
        id: 's0',
        label: '뉴스관리',
        path: '/ir/news/list'
      },
      {
        id: 's1',
        label: '공지관리',
        path: '/ir/notice/list'
      },
    ]
  },

  {
    id: 2,
    label: 'Career',
    sub: [
      {
        id: 's0',
        label: '채용공고',
        path: '/career/jobs/list'
      },
      {
        id: 's1',
        label: 'Life',
        path: '/career/life/list'
      },
    ]
  },

]
const infoMenu = [
  {
    id: 3,
    label: '회사 정보 관리',
    sub: [
      {
        id: 's0',
        label: 'BECUAI 사이트 설정',
        path: '/setting/becuai'
      },
    ]
  },
]

const Index = () => {

  const auth = useRecoilValue(authAtom)
  const [isOpen, setIsOpen] = useState<any>([0, 1, 2, 3])

  const getStatus = (id: number) => {
    return isOpen.findIndex((element: any) => element === id) > -1 ? true : false
  }

  const handleChange = (id: number) => {
    setIsOpen(() => {
      return (
        getStatus(id) ? isOpen.filter((item: any) => item !== id) : isOpen.concat(id)
      )
    })
  }

  return (
    <Style.Container>
      <Style.Nav>
        <Style.NavTitle>콘텐츠 관리</Style.NavTitle>
        {contentMenu.map((item: any, index: number) => {
            return (
              <Style.NavItem key={index}>
                {
                  !item.path ?
                    <Style.NavItemLabel
                      selected={getStatus(item.id)}
                      onClick={() => handleChange(item.id)}
                    >
                      {item.label}
                      {
                        item.sub &&
                          <Svg
                              icon={'arrow'}
                              width={10}
                              height={10}
                              color={'#666666'}
                          />
                      }
                    </Style.NavItemLabel>
                    :
                    <Style.NavLinkItemLabel to={item.path}>
                      {item.label}
                    </Style.NavLinkItemLabel>
                }

                {item.sub &&
                  <Style.NavSubItem selected={getStatus(item.id)}>
                    {item.sub.map((sub: any, subIndex: number) => {
                        return (
                          <Style.NavSubItemLabel
                            key={subIndex}
                            to={sub.path}
                            end
                          >
                            {sub.label}
                          </Style.NavSubItemLabel>
                        )
                      })}
                  </Style.NavSubItem>}
              </Style.NavItem>
            )
          })}

        <Style.NavTitle>정보관리</Style.NavTitle>
        {infoMenu.map((item: any, index: number) => {
          return (
            <Style.NavItem key={index}>
              {
                !item.path ?
                  <Style.NavItemLabel
                    selected={getStatus(item.id)}
                    onClick={() => handleChange(item.id)}
                  >
                    {item.label}
                    {
                      item.sub &&
                      <Svg
                        icon={'arrow'}
                        width={10}
                        height={10}
                        color={'#666666'}
                      />
                    }
                  </Style.NavItemLabel>
                  :
                  <Style.NavLinkItemLabel to={item.path}>
                    {item.label}
                  </Style.NavLinkItemLabel>
              }

              {item.sub &&
                <Style.NavSubItem selected={getStatus(item.id)}>
                  {item.sub.map((sub: any, subIndex: number) => {
                    return (
                      <Style.NavSubItemLabel
                        key={subIndex}
                        to={sub.path}
                        end
                      >
                        {sub.label}
                      </Style.NavSubItemLabel>
                    )
                  })}
                </Style.NavSubItem>}
            </Style.NavItem>
          )
        })}
      </Style.Nav>
    </Style.Container>
  )
};

export default Index
