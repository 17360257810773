import React from 'react'
import {
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

import {useRecoilState, useRecoilValue} from 'recoil'
import {authAtom} from '../store/recoil/auth'

/*************
 LayOut
 *************/

import DefaultLayout from '../layout/DefaultLayout'

/*************
 Auth
 *************/

import AuthRoute from './AuthRoute'

/*************
 public
 *************/

import Auth from '../page/auth'
import NotFound from '../page/NotFound'

/*************
 auth
 *************/

import Index from '../page/'
import Main from "../page/main";
import News from "../page/ir/news";
import Notice from "../page/ir/noitce";
import Jobs from "../page/career/jobs";
import Life from "../page/career/life";
import Setting from "../page/setting";

const Routers = () => {

  const auth = useRecoilValue(authAtom)

  const publicRoutes = [
    {name: 'Auth', path: '/auth/*', element: Auth},
    {name: '404', path: '/*', element: NotFound},
  ]

  const authRoutes = [
    {name: 'Index', path: '/', element: Index},
    {name: 'Main', path: '/main/*', element: Main},
    {name: 'IR', path: '/ir/news/*', element: News},
    {name: 'IR', path: '/ir/notice/*', element: Notice},
    {name: 'CAREER', path: '/career/jobs/*', element: Jobs},
    {name: 'CAREER', path: '/career/life/*', element: Life},
    {name: 'SETTING', path: '/setting/*', element: Setting},
  ]

  return (
    <Routes>
      <Route
        path="/" element={
        <AuthRoute auth={auth}>
          <DefaultLayout/>
        </AuthRoute>
      }>
        {authRoutes.map((route, key) => (
          <Route
            key={key}
            path={route.path}
            element={<route.element/>}
          />
        ))}
      </Route>

      {publicRoutes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          element={<route.element/>}
        />
      ))}
    </Routes>
  )
}

export default Routers
