import React, {useEffect} from 'react'

import styled from 'styled-components'
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 1rem;
`

const Index = () => {

  const navigate = useNavigate()

  useEffect(() => {
    navigate('main/pop/list')
  }, [])
  return (
    <Container>
    </Container>
  )
}

export default Index
