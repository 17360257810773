import {Route, Routes} from "react-router-dom";
import {lazy, Suspense} from "react";
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
`

const Index = () => {
  const Becuai = lazy(() => import('./becuai'))

  return <Container>
    <Suspense fallback={'/'}>
      <Routes>
        <Route path={'/becuai'} element={<Becuai />} />
      </Routes>
    </Suspense>
  </Container>
}

export default Index
