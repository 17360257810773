import styled from 'styled-components'
import {Link, NavLink} from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 9.7rem;
  position: relative;
  background: #ffffff;
  padding: 1rem 0 0 0;
`

const Nav = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 10rem;
  position: sticky;
  top: 2.5rem;
  overflow: auto;
  
  &::-webkit-scrollbar {
    display: none;
  }
`

const NavItem = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 0 .5rem 0;
  padding: .2rem 0 .2rem 0;
  border-radius: 1.2rem;
`

const NavItemLabel = styled.ul<{ selected: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 0 0 0 0;
  border-radius: 1.2rem;
  cursor: pointer;

  svg {
    margin-top: -.4rem;
    transform: ${({selected}) => (!selected ? 'rotate(-90deg)' : 'rotate(90deg);')};
    transition: all 0.3s;
  }
`

const NavLinkItemLabel = styled(NavLink)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 0 0 0 0;
  border-radius: 1.2rem;

  &.active {
    color: #cb2121;
  }
`

const NavSubItem = styled.ul<{ selected: boolean }>`
  font-weight: 400;
  font-size: 1.4rem;
  overflow: ${({selected}) => (!selected ? 'hidden' : 'auto')};
  height: ${({selected}) => (!selected ? '0' : 'auto')};
  padding: ${({selected}) => (!selected ? '0' : '1rem 0 1rem 0')};
  transition: all 0.3s;
`

const NavSubItemLabel = styled(NavLink)`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.4rem;
  padding: .4rem 0rem .4rem 0rem;
  color: #666666;

  &.active {
    font-weight: bold;
  }
`

const NavTitle = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  padding: .4rem 0rem .4rem 0rem;
  color: #666666;
`

export const Style = {
  Container,
  Nav,
  NavItem,
  NavItemLabel,
  NavLinkItemLabel,
  NavSubItem,
  NavSubItemLabel,
  NavTitle
}
